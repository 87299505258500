<!-- 新增、编辑活动 -->

<template>
  <div class="activity-dialog">
    <w-dialog ref="dialogRef" class="vip-dialog" :title="ruleForm.ac_id ? '编辑活动' : '新增活动'" width="60%" btnWidth="140px"
      top="10vh" :confirmText="ruleForm.ac_id ? '确认编辑' : '确认新增'" @wConfirm="handleSure">
      <el-form class="add-form" ref="formRef" :model="ruleForm" :rules="formRules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="所属园区" prop="ac_sid">
              <el-select v-model="ruleForm.ac_sid" clearable placeholder="请选择所属园区">
                <el-option :label="item.s_name" :value="item.s_id" v-for="item in scenicOptions" :key="item.s_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="名称" prop="ac_name">
              <el-input v-model="ruleForm.ac_name" clearable placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="活动类型" prop="ac_type">
              <el-select v-model="ruleForm.ac_type" clearable placeholder="请选择活动类型">
                <el-option :label="item.title" :value="item.id" v-for="item in typeOptions" :key="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="活动周期" prop="cycleDate">
              <el-date-picker v-model="ruleForm.cycleDate" type="daterange" range-separator="至" start-placeholder="开始时间"
                end-placeholder="结束时间" value-format="YYYY-MM-DD" :clearable="false">
              </el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="数量" prop="ac_number">
              <el-input type="number" v-model="ruleForm.ac_number" :disabled="ruleForm.ac_id ? true:false" clearable placeholder="请输入存量"
                oninput="if(value < 0) value = 0">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item prop="ac_desc" label="使用说明">
              <el-input type="textarea" rows="3" placeholder="请输入使用说明" v-model.trim="ruleForm.ac_desc"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script>
  import { ref, onMounted } from "vue";
  import { ElMessage } from "element-plus";
  import { VipApi, BasicApi } from "@/plugins/api.js";

  export default {
    emits: ["submit"],
    components: {},
    setup(props, { emit }) {
      const dialogRef = ref(null);  // 弹框对象
      const typeOptions = ref([]);  // 活动类型选项
      const scenicOptions = ref([]);  // 园区选项
      const formRef = ref(null);  // 表单对象
      const formRules = ref({  // 验证必填项
        ac_sid: [{ required: true, message: "请选择所属园区", trigger: "blur", },],
        ac_name: [{ required: true, message: "请输入名称", trigger: "blur", },],
        ac_type: [{ required: true, message: "请选择活动类型", trigger: "blur", },],
        cycleDate: [{ required: true, message: "请选择活动周期", trigger: "blur", },],
        ac_number: [{ required: true, message: "请输入数量", trigger: "blur", },],
      });
      const ruleForm = ref({
        ac_sid: '',  // 园区
        ac_name: '',  // 名称
        ac_type: '',  // 活动类型
        cycleDate: [],  // 活动周期
        ac_stime: '',  // 开始时间
        ac_etime: '',  // 结束时间
        ac_number: '',  // 数量
        ac_desc: '',  // 使用说明
      });
      /**
       * 
       * 打开弹框
       * 
       * */
      const openDialog = (row) => {
        if (row.ac_id) {
          // 编辑
          ruleForm.value.ac_id = row.ac_id;
          ruleForm.value.ac_sid = Number(row.ac_sid);
          ruleForm.value.ac_name = row.ac_name;
          ruleForm.value.ac_type = Number(row.ac_type);
          ruleForm.value.cycleDate = [row.ac_stime, row.ac_etime];
          ruleForm.value.ac_number = row.ac_number;
          ruleForm.value.ac_desc = row.ac_desc;
        } else {
          // 新增
          ruleForm.value = {
            ac_sid: '',  // 园区
            ac_name: '',  // 名称
            ac_type: '',  // 活动类型
            cycleDate: [],  // 活动周期
            ac_stime: '',  // 开始时间
            ac_etime: '',  // 结束时间
            ac_number: '',  // 数量
            ac_desc: '',  // 使用说明
          };
        }
        dialogRef.value.show();
      }
      /**
       * 
       * 关闭弹框
       * 
       * */
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 获取园区选项数据
       * 
       **/
      const getScenicByProject = () => {
        BasicApi.getScenicByProject({}).then((res) => {
          if (res.Code === 200) {
            scenicOptions.value = res.Data ? res.Data : [];
          } else {
            let msg = res.Message ? res.Message : "获取园区数据失败！";
            ElMessage.error(msg);
          }
        });
      }
      /**
       * 
       * 获取活动类型
       * 
       * */
      const getActiveTypes = () => {
        VipApi.getActiveTypes().then((res) => {
          if (res.Code === 200) {
            typeOptions.value = res.Data ? res.Data : [];
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 确定新增按钮
       * 
       * */
      const handleSure = () => {
        if (formRef.value) {
          formRef.value.validate((valid) => {
            if (valid) {
              let parmas = {
                ac_sid: ruleForm.value.ac_sid,
                ac_name: ruleForm.value.ac_name,
                ac_desc: ruleForm.value.ac_desc,
                ac_stime: ruleForm.value.cycleDate[0],
                ac_etime: ruleForm.value.cycleDate[1],
                ac_number: ruleForm.value.ac_number,
                ac_type: ruleForm.value.ac_type,
                ac_id: ruleForm.value.ac_id,
              };
              // console.log("提交", parmas);
              emit("submit", parmas);
            }
          });
        }
      }

      onMounted(() => {
        getActiveTypes();
        getScenicByProject();
      });

      return {
        dialogRef,
        openDialog,
        typeOptions,
        handleSure,
        formRef,
        ruleForm,
        formRules,
        scenicOptions,
        getScenicByProject,
        closeDialog,
      };
    },
  };
</script>

<style lang="scss">
  .activity-dialog {

    .el-input-group__append,
    .el-input-group__prepend {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .el-input-group__append {
      border-left: 1px solid #e6e3e3;
    }

    .el-input-group__prepend {
      border-right: 1px solid #e6e3e3;
    }

    .el-form-item__content {
      display: flex;
      align-items: center;
      dth: 100%;
    }


    .vip-dialog {
      .el-dialog {
        min-width: 600px;

        .el-dialog__body {
          padding: 30px;

          .el-divider--horizontal {
            margin: 10px 0;
          }
        }
      }
    }
  }
</style>