<!-- 会员服务-活动管理 -->

<template>
  <div class="activity-container main-cnt">
    <div class="title">活动列表</div>
    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :ischeck="false" :filters="filters"
        :ispaging="true" :apiName="VipApi.getCtiveCodeLists" :columns="tableColumns" @edit="openDialog"
        @statusChange="changeActivityStatus" @onViewDetails="onViewDetails" @downCouponCode="downCouponCode">
        <template #operate>
          <el-button type="primary" round @click="openDialog" v-if="authData.indexOf('m_B8apcwB0K9xO7fcukFDV') != -1">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增活动
          </el-button>
        </template>
      </common-table>
    </div>

    <!--详情 弹框 -->
    <w-dialog ref="detailsDialog" class="photo-dialog" title="详情" width="60%" btnWidth="140px" top="10vh"
      :hideFooter="true">
      <div style="text-align: end;">
        <el-button type="primary" round @click="detaDownCouponCode"
          v-if="authData.indexOf('m_5NysKVUIlDS4McECBZsz') != -1">
          下载券码
        </el-button>
      </div>
      <common-table ref="detailsTableRef" tableHeight="calc(100vh - 360px)" :ischeck="false" :ispaging="true"
        :apiName="VipApi.getActiveDetails" :columns="detailsColumns" :extraParame="{ ac_id: rowId }">
      </common-table>
    </w-dialog>

    <!-- 新增、编辑活动 -->
    <ActivityDialog ref="activityDialog" @submit="handleSubmit"></ActivityDialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, nextTick, computed, watch } from "vue";
  import { VipApi, BasicApi } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import ActivityDialog from "../components/ActivityDialog.vue";
  import { exportExcel } from "@/utils/common.js";
  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  const tableRef = ref(null);  // 表格对象
  const activityDialog = ref(null);  // 新增、编辑活动弹框对象
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "ac_name",
      label: "名称",
    },
    {
      prop: "belong_name",
      label: "所属园区",
      color: "--text-third-color",
    },
    {
      prop: "typename",
      label: "活动类型",
      color: "--text-third-color",
    },
    {
      prop: "ac_number",
      label: "数量",
      color: "--text-third-color",
    },
    {
      prop: "ac_status",
      type: "switch",
      label: "活动状态",
      token: "m_C2dC8nVGgecVeVZb6xKn",
      minWidth: 80,
    },
    {
      prop: "ac_stime",
      prop2: "ac_etime",
      label: "活动周期",
      color: "--text-third-color",
      type: "connect",
      connectText: "-",
      minWidth: 210,
    },
    {
      prop: "ac_dow",
      label: "状态",
      color: "--text-third-color",
      type: "option",
      metaData: ["", "待下载", "已下载"],
      colorData: ["", "#69da61", "#999999"],
    },
    {
      prop: "ac_dow_time",
      label: "下载时间",
      color: "--text-third-color",
    },
    {
      prop: "ac_desc",
      label: "使用说明",
      color: "--text-third-color",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 300,
      bottons: [
        {
          name: "详情",
          action: "onViewDetails",
          token: "m_xXiXmmgCpsViixbD20Vl",
        },
        {
          name: "编辑",
          action: "edit",
          token: "m_IRHIIFKjQ9My0nPUH68O",
        },
        {
          name: "下载券码",
          action: "downCouponCode",
          token: "m_5NysKVUIlDS4McECBZsz",
        },
      ],
    },
  ]);
  // 筛选条件
  const filters = ref([
    {
      filterType: "select",
      name: "s_id",
      value: "",
      placeholder: "请选择园区",
      options: [],
      val: "s_id",
      lab: "s_name",
    },
    {
      filterType: "select",
      name: "ac_type",
      value: "",
      placeholder: "请选择活动类型",
      options: [],
      val: "id",
      lab: "title",
    },
  ]);
  /**
   * 
   * 获取园区选项数据
   * 
   **/
  const getScenicByProject = () => {
    BasicApi.getScenicByProject({}).then((res) => {
      if (res.Code === 200) {
        filters.value[0].options = res.Data ? res.Data : [];
      } else {
        let msg = res.Message ? res.Message : "获取园区数据失败！";
        ElMessage.error(msg);
      }
    });
  }
  /**
   * 
   * 点击新增、编辑活动按钮打开弹框
   * 
   **/
  const openDialog = (row) => {
    activityDialog.value.openDialog(row);
  };
  /**
   * 
   * 新增、编辑活动确定提交按钮
   * 
   * */
  const handleSubmit = (data) => {
    activityDialog.value.dialogRef.isLoading = true;
    let url = data.ac_id ? "editInsertActive" : "addInsertActive";
    VipApi[url](data).then((res) => {
      activityDialog.value.dialogRef.isLoading = false;
      if (res.Code === 200) {
        ElMessage.success(`操作成功！`);
        activityDialog.value.closeDialog();
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 修改活动状态
   * 
   **/
  const changeActivityStatus = (row) => {
    let data = {
      ac_id: row.ac_id,
      ac_status: row.ac_status == 1 ? 2 : 1,
    };
    VipApi.changeActiveStatus(data).then((res) => {
      if (res.Code === 200) {
        ElMessage.success("修改成功！");
        tableRef.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 获取活动类型
   * 
   * */
  const getActiveTypes = () => {
    VipApi.getActiveTypes().then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const detailsDialog = ref(null);  // 详情弹框对象
  const detailsTableRef = ref(null);  // 详情表格对象
  const rowId = ref(null);
  const detailsColumns = ref([
    {
      prop: "acl_value",
      label: "券码编号",
    },
    {
      prop: "acl_status",
      label: "状态",
      color: "--text-third-color",
      type: "option",
      metaData: ["", "待使用", "已使用"],
      colorData: ["", "#69da61", "#999999"],
    },
    {
      prop: "acl_ctime",
      label: "使用时间",
      color: "--text-third-color",
    },
    // {
    //   type: "operation",
    //   prop: "",
    //   label: "操作",
    //   minWidth: 100,
    //   bottons: [
    //     {
    //       name: "下载券码",
    //       action: "detaDownCouponCode",
    //       token: "m_5NysKVUIlDS4McECBZsz",
    //     },
    //   ],
    // },
  ]);
  /**
   * 
   * 详情按钮
   * 
   * */
  const onViewDetails = (row) => {
    rowId.value = row.ac_id;
    detailsDialog.value.show();
    nextTick(() => {
      detailsTableRef.value.tableLoad();
    });
  }
  // 下载券码标题
  const propertiesConfig = ref([
    { field: "acl_value", displayName: "券码编号" },
  ]);
  /**
   * 
   * 下载券码按钮
   * 
   * */
  const downCouponCode = (row) => {
    VipApi.getExportCode({ ac_id: row.ac_id, status: 0, all: 1 }).then((res) => {
      if (res.Code == 200) {
        if (res.Data.length > 0) {
          try {
            exportExcel(
              row.ac_name,
              res.Data,
              propertiesConfig.value
            );
            ElMessage.success({
              message: "导出成功！",
            });
            tableRef.value.tableLoad();
          } catch (e) {
            ElMessage.error({
              message: "导出失败！",
            });
          }
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  // 下载券码标题
  const detailsConfig = ref([
    { field: "acl_value", displayName: "券码编号" },
    { field: "acl_sta_str", displayName: "状态" },
    { field: "acl_check_time_str", displayName: "使用时间" },
  ]);
  /**
   * 
   * 详情下载券码按钮
   * 
   * */
  const detaDownCouponCode = (row) => {
    VipApi.getExportCode({ ac_id: rowId.value, status: 0, all: 2 }).then((res) => {
      if (res.Code == 200) {
        if (res.Data.length > 0) {
          try {
            exportExcel(
              row.ac_name,
              res.Data,
              detailsConfig.value
            );
            ElMessage.success({
              message: "导出成功！",
            });
          } catch (e) {
            ElMessage.error({
              message: "导出失败！",
            });
          }
        }
      } else {
        ElMessage.error(res.Message);
      }
    });
  }

  onMounted(() => {
    getScenicByProject();
    getActiveTypes();
    tableRef.value.tableLoad();
  });
</script>
<style lang="scss">
  .activity-container {
    font-family: "Source Han Sans CN";

    .content {
      padding: 15px 20px 20px;
    }
  }
</style>